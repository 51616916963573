import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';

const FraudProtectionPage = ({ data }) => (
  <Layout>
    <SEO title="Fraud Protection" />
    <div className="container page pt-16 pb-20 lg:max-w-lg body-content">
      <h1 className="hero-title mb-8 md:mb-12 lg:text-center">
        My Commitment to Fraud Protection
      </h1>
      <p>
        My reputation is very important to me. I am keenly aware that the
        accuracy of my work could make it very easy for someone to represent my
        recreations as original and team issued. To ensure that my work is never
        misrepresented, I have a very simple set of rules I follow without
        exception:
      </p>
      <ul>
        <li>
          I will not alter manufacturer labels, nor will I alter or recreate
          team tagging. I surely understand that many of my projects are
          intended to be kept by their owners forever and proudly displayed. But
          the opportunity to become an unwitting part of fraud should the jersey
          be misrepresented and sold in a future transaction as something that
          it is not, causes me to draw the line here.
          <br />
          <br />
        </li>
        <li>
          When performing a player name or number change on a team issued jersey
          (or one that could easily be mistaken for team issued by a
          knowledgeable buyer) I do three things:
          <ul>
            <li>
              I sew my shop’s label inside the jersey, into the back of the
              player name or numbers. It cannot be seen from the outside, but
              will be obvious on close inspection, and cannot be removed without
              disturbing the stitching.
              <Img fluid={data.file.childImageSharp.fluid} className="my-6 md:my-8" />
            </li>
            <li>
              I invisibly mark every such garment for potential future
              identification.
            </li>
            <li>I maintain a database of all such work for later reference.</li>
          </ul>
        </li>
      </ul>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    file(name: { eq: "dream-shop-tag" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default FraudProtectionPage;
